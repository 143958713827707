define("ember-bootstrap/components/bs-form/element/layout/inline/checkbox", ["exports", "ember-bootstrap/components/bs-form/element/layout/inline"], function (_exports, _inline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-check">
    {{yield}}
    {{component @labelComponent}}
    {{component @errorsComponent}}
    {{component @helpTextComponent}}
  </div>
  */
  {
    "id": "7/sebME9",
    "block": "[[[10,0],[14,0,\"form-check\"],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n  \"],[46,[30,1],null,null,null],[1,\"\\n  \"],[46,[30,2],null,null,null],[1,\"\\n  \"],[46,[30,3],null,null,null],[1,\"\\n\"],[13]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/inline/checkbox.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutInlineCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutInlineCheckbox extends _inline.default {}

  _exports.default = FormElementLayoutInlineCheckbox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementLayoutInlineCheckbox);
});