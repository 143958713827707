define("ember-bootstrap/components/bs-modal/header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isBS4"))}}
    <h5 class="modal-title" ...attributes>
      {{yield}}
    </h5>
  {{/if}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <h4 class="modal-title" ...attributes>
      {{yield}}
    </h4>
  {{/if}}
  */
  {
    "id": "LYoRW3av",
    "block": "[[[1,\"  \"],[11,\"h5\"],[24,0,\"modal-title\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/header/title.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});