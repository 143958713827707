define("ember-bootstrap/components/bs-accordion/item", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _defaultDecorator, _typeClass, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="{{if this.disabled "disabled"}} {{this.typeClass}} {{if (macroCondition (macroGetOwnConfig "isBS4")) "card"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel"}}"
    ...attributes
  >
    {{#if hasBlockParams}}
      {{yield
        (hash
          title=(component (bs-default @titleComponent (component "bs-accordion/item/title")) collapsed=this.collapsed disabled=this.disabled onClick=(action onClick this.value))
          body=(component (bs-default @bodyComponent (component "bs-accordion/item/body")) collapsed=this.collapsed)
        )
      }}
    {{else}}
      {{#component (bs-default @titleComponent (component "bs-accordion/item/title")) collapsed=this.collapsed disabled=this.disabled onClick=(action onClick this.value)}}
        {{title}}
      {{/component}}
      {{#component (bs-default @bodyComponent (component "bs-accordion/item/body")) collapsed=this.collapsed}}
        {{yield}}
      {{/component}}
    {{/if}}
  
  </div>
  */
  {
    "id": "7fYXmxtq",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"disabled\"]],\"disabled\"],\" \",[30,0,[\"typeClass\"]],\" \",\"card\",\" \",[27]]]],[17,1],[12],[1,\"\\n\"],[41,[49,[30,4]],[[[1,\"    \"],[18,4,[[28,[37,3],null,[[\"title\",\"body\"],[[50,[28,[37,5],[[30,2],[50,\"bs-accordion/item/title\",0,null,null]],null],0,null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,0,[\"disabled\"]],[28,[37,6],[[30,0],[33,7],[30,0,[\"value\"]]],null]]]],[50,[28,[37,5],[[30,3],[50,\"bs-accordion/item/body\",0,null,null]],null],0,null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]]]]]]]],[1,\"\\n\"]],[]],[[[46,[28,[37,5],[[30,2],[50,\"bs-accordion/item/title\",0,null,null]],null],null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,0,[\"disabled\"]],[28,[37,6],[[30,0],[33,7],[30,0,[\"value\"]]],null]]],[[\"default\"],[[[[1,\"      \"],[1,[34,8]],[1,\"\\n\"]],[]]]]],[46,[28,[37,5],[[30,3],[50,\"bs-accordion/item/body\",0,null,null]],null],null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]],[[\"default\"],[[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]]]]]],[]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@titleComponent\",\"@bodyComponent\",\"&default\"],false,[\"if\",\"has-block-params\",\"yield\",\"hash\",\"component\",\"bs-default\",\"action\",\"onClick\",\"title\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item.hbs",
    "isStrictMode": false
  });

  let AccordionItem = (
  /**
   A collapsible/expandable item within an accordion
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.tagName)(''), _dec2 = Ember.computed('value', 'selected').readOnly(), _dec3 = Ember.computed.not('collapsed'), _dec4 = (0, _typeClass.default)('bg', 'type'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class AccordionItem extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "title", _descriptor, this);

      _defineProperty(this, "value", Ember.guidFor(this));

      _initializerDefineProperty(this, "selected", _descriptor2, this);

      _initializerDefineProperty(this, "active", _descriptor3, this);

      _initializerDefineProperty(this, "disabled", _descriptor4, this);

      _initializerDefineProperty(this, "type", _descriptor5, this);

      _initializerDefineProperty(this, "typeClass", _descriptor6, this);
    }

    /**
     * @property titleComponent
     * @type {String}
     * @private
     */

    /**
     * @property bodyComponent
     * @type {String}
     * @private
     */

    /**
     * @property collapsed
     * @type boolean
     * @readonly
     * @private
     */
    get collapsed() {
      return this.value !== this.selected;
    }
    /**
     * @property active
     * @type boolean
     * @readonly
     * @private
     */


    /**
     * Reference to the parent `Components.Accordion` class.
     *
     * @property accordion
     * @private
     */

    /**
     * @event onClick
     * @public
     */
    onClick() {}

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "collapsed"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = AccordionItem;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AccordionItem);
});