define("admin-lte-ember/components/alte/main-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <aside class="main-sidebar sidebar-dark-primary elevation-4" ...attributes {{alte/main-sidebar}}>
    {{yield}}
  </aside>
  
  */
  {
    "id": "VnpuKvD7",
    "block": "[[[11,\"aside\"],[24,0,\"main-sidebar sidebar-dark-primary elevation-4\"],[17,1],[4,[38,0],null,null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"alte/main-sidebar\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/main-sidebar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});