define("ember-bootstrap/components/bs-form/element/layout/horizontal", ["exports", "ember-bootstrap/components/bs-form/element/layout", "ember-bootstrap/utils/default-decorator"], function (_exports, _layout, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.hasLabel}}
    {{component @labelComponent labelClass=@horizontalLabelGridClass}}
    <div class={{this.horizontalInputGridClass}}>
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        {{component @feedbackIconComponent}}
      {{/if}}
      {{component @errorsComponent}}
      {{component @helpTextComponent}}
    </div>
  {{else}}
    <div class="{{this.horizontalInputGridClass}} {{this.horizontalInputOffsetGridClass}}">
      {{yield}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        {{component @feedbackIconComponent}}
      {{/if}}
      {{component @errorsComponent}}
      {{component @helpTextComponent}}
    </div>
  {{/if}}
  */
  {
    "id": "0uGSZ0CI",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[46,[30,1],null,[[\"labelClass\"],[[30,2]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"horizontalInputGridClass\"]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[46,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[46,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@labelComponent\",\"@horizontalLabelGridClass\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"component\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/horizontal.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutHorizontal
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  let FormElementLayoutHorizontal = (_dec = Ember.computed('horizontalLabelGridClass').readOnly(), _dec2 = Ember.computed('horizontalLabelGridClass'), (_class = class FormElementLayoutHorizontal extends _layout.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "horizontalLabelGridClass", _descriptor, this);
    }

    /**
     * Computed property that specifies the Bootstrap grid class for form controls within a horizontal layout form.
     *
     * @property horizontalInputGridClass
     * @type string
     * @readonly
     * @private
     */
    get horizontalInputGridClass() {
      if (Ember.isBlank(this.horizontalLabelGridClass)) {
        return undefined;
      }

      let parts = this.horizontalLabelGridClass.split('-');
      (false && !(parts.length === 3) && Ember.assert('horizontalInputGridClass must match format bootstrap grid column class', parts.length === 3));
      parts[2] = 12 - parts[2];
      return parts.join('-');
    }
    /**
     * Computed property that specifies the Bootstrap offset grid class for form controls within a horizontal layout
     * form, that have no label.
     *
     * @property horizontalInputOffsetGridClass
     * @type string
     * @readonly
     * @private
     */


    get horizontalInputOffsetGridClass() {
      if (Ember.isBlank(this.horizontalLabelGridClass)) {
        return undefined;
      }

      let parts = this.horizontalLabelGridClass.split('-');
      {
        parts.splice(0, 1, 'offset');
      }
      return parts.join('-');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "horizontalInputGridClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputGridClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "horizontalInputOffsetGridClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalInputOffsetGridClass"), _class.prototype)), _class));
  _exports.default = FormElementLayoutHorizontal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementLayoutHorizontal);
});