define("admin-lte-ember/modifiers/alte/push-menu", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AltePushMenuModifier = (_dec = Ember._action, (_class = (_temp = _class2 = class AltePushMenuModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "autoCollapseSize", 992);

      _defineProperty(this, "autoCollapseOnResizeBoundFunction", void 0);

      _defineProperty(this, "collapseBoundFunction", void 0);

      _defineProperty(this, "enableRemember", false);

      _defineProperty(this, "overlayDiv", void 0);

      _defineProperty(this, "noTransitionAfterReload", true);

      this.autoCollapseOnResizeBoundFunction = this.autoCollapseOnResize.bind(this);
      this.collapseBoundFunction = this.collapse.bind(this);
    }

    autoCollapse() {
      if (this.autoCollapseSize && window.innerWidth <= this.autoCollapseSize) {
        if (!document.body.classList.contains(AltePushMenuModifier.CLASS_NAME_OPEN)) {
          this.collapse();
        }
      }
    }

    autoCollapseOnResize() {
      if (this.autoCollapseSize) {
        if (window.innerWidth <= this.autoCollapseSize) {
          if (!document.body.classList.contains(AltePushMenuModifier.CLASS_NAME_OPEN)) {
            this.collapse();
          }
        } else {
          if (document.body.classList.contains(AltePushMenuModifier.CLASS_NAME_OPEN)) {
            document.body.classList.remove(AltePushMenuModifier.CLASS_NAME_OPEN);
          }
        }
      }
    }

    collapse() {
      if (this.autoCollapseSize) {
        if (window.innerWidth <= this.autoCollapseSize) {
          document.body.classList.remove(AltePushMenuModifier.CLASS_NAME_OPEN);
        }
      }

      document.body.classList.add(AltePushMenuModifier.CLASS_NAME_COLLAPSED);

      if (this.enableRemember) {
        localStorage.setItem("remember".concat(AltePushMenuModifier.EVENT_KEY), AltePushMenuModifier.CLASS_NAME_COLLAPSED);
      }

      if (this.args.named.onCollapsed) {
        this.args.named.onCollapsed();
      }
    }

    didInstall() {
      this.init();

      if (this.element) {
        this.element.addEventListener('click', this.onClick, true);
      }
    }

    didReceiveArguments() {
      if (this.args.named.autoCollapseSize) {
        this.autoCollapseSize = this.args.named.autoCollapseSize;
      }

      if (this.args.named.enableRemember) {
        this.enableRemember = this.args.named.enableRemember;
      }

      if (this.args.named.noTransitionAfterReload) {
        this.noTransitionAfterReload = this.args.named.noTransitionAfterReload;
      }
    }

    expand() {
      if (this.autoCollapseSize) {
        if (window.innerWidth <= this.autoCollapseSize) {
          document.body.classList.add(AltePushMenuModifier.CLASS_NAME_OPEN);
        }
      }

      document.body.classList.remove(AltePushMenuModifier.CLASS_NAME_COLLAPSED);

      if (this.enableRemember) {
        localStorage.setItem("remember".concat(AltePushMenuModifier.EVENT_KEY), AltePushMenuModifier.CLASS_NAME_OPEN);
      }

      if (this.args.named.onShown) {
        this.args.named.onShown();
      }
    }

    onClick(event) {
      event.preventDefault();
      event.stopPropagation();
      this.toggle();
    }

    remember() {
      if (this.enableRemember) {
        const toggleState = localStorage.getItem("remember".concat(AltePushMenuModifier.EVENT_KEY));

        if (toggleState === AltePushMenuModifier.CLASS_NAME_COLLAPSED) {
          if (this.noTransitionAfterReload) {
            document.body.classList.add('hold-transition', AltePushMenuModifier.CLASS_NAME_COLLAPSED);
            Ember.run.later(() => {
              document.body.classList.remove('hold-transition');
            }, 50);
          } else {
            document.body.classList.add(AltePushMenuModifier.CLASS_NAME_COLLAPSED);
          }
        } else {
          if (this.noTransitionAfterReload) {
            document.body.classList.add('hold-transition');
            document.body.classList.remove('hold-transition', AltePushMenuModifier.CLASS_NAME_COLLAPSED);
            Ember.run.later(() => {
              document.body.classList.remove('hold-transition');
            }, 50);
          } else {
            document.body.classList.remove(AltePushMenuModifier.CLASS_NAME_COLLAPSED);
          }
        }
      }
    }

    toggle() {
      if (!document.body.classList.contains(AltePushMenuModifier.CLASS_NAME_COLLAPSED)) {
        this.collapse();
      } else {
        this.expand();
      }
    }

    willRemove() {
      if (this.element) {
        this.element.removeEventListener('click', this.onClick, true);
      }

      window.removeEventListener('resize', this.autoCollapseOnResizeBoundFunction);

      if (this.overlayDiv) {
        this.overlayDiv.removeEventListener('click', this.collapseBoundFunction);
        this.overlayDiv.remove();
      }
    }

    createOverlayDiv() {
      const wrapper = document.getElementsByClassName(AltePushMenuModifier.CLASS_NAME_WRAPPER).item(0);

      if (wrapper) {
        let overlayDiv = document.getElementById(AltePushMenuModifier.ID_OVERLAY);

        if (!overlayDiv) {
          overlayDiv = document.createElement('div');
          overlayDiv.setAttribute('id', AltePushMenuModifier.ID_OVERLAY);
        }

        wrapper.append(overlayDiv);
        overlayDiv.addEventListener('click', this.collapseBoundFunction);
        this.overlayDiv = overlayDiv;
      }
    }

    init() {
      this.createOverlayDiv();
      this.remember();
      this.autoCollapse();
      window.addEventListener('resize', this.autoCollapseOnResizeBoundFunction);
    }

  }, _defineProperty(_class2, "CLASS_NAME_COLLAPSED", 'sidebar-collapse'), _defineProperty(_class2, "CLASS_NAME_OPEN", 'sidebar-open'), _defineProperty(_class2, "CLASS_NAME_WRAPPER", 'wrapper'), _defineProperty(_class2, "ID_OVERLAY", 'sidebar-overlay'), _defineProperty(_class2, "EVENT_KEY", '.lte.pushmenu'), _temp), (_applyDecoratedDescriptor(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = AltePushMenuModifier;
});