define("ember-bootstrap/components/bs-form/element/label", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    {{#if hasBlock}}
      <label>
        {{yield}}
        {{@label}}
      </label>
    {{else}}
      <label class="control-label {{if this.invisibleLabel "sr-only"}} {{this.labelClass}}" for={{@formElementId}}>{{@label}}</label>
    {{/if}}
  {{else}}
    <label class="{{if this.invisibleLabel "sr-only"}} {{this.labelClass}} {{if this.isHorizontalAndNotCheckbox "col-form-label"}} {{if this.isCheckbox "form-check-label"}} {{this.sizeClass}}" for={{@formElementId}}>
      {{#if hasBlock}}
        {{yield}}
      {{/if}}
      {{@label}}
    </label>
  {{/if}}
  */
  {
    "id": "/AIRn2V0",
    "block": "[[[1,\"  \"],[10,\"label\"],[15,0,[29,[[52,[30,0,[\"invisibleLabel\"]],\"sr-only\"],\" \",[30,0,[\"labelClass\"]],\" \",[52,[30,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],\" \",[52,[30,0,[\"isCheckbox\"]],\"form-check-label\"],\" \",[30,0,[\"sizeClass\"]]]]],[15,\"for\",[30,1]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@formElementId\",\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/label.hbs",
    "isStrictMode": false
  });

  let FormElementLabel = (
  /**
  
   @class FormElementLabel
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.tagName)(''), _dec2 = Ember.computed('isHorizontal', 'isCheckbox'), _dec3 = Ember.computed('size', 'isHorizontal'), _dec4 = Ember.computed.equal('controlType', 'checkbox').readOnly(), _dec5 = Ember.computed.equal('formLayout', 'horizontal').readOnly(), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementLabel extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "invisibleLabel", _descriptor, this);

      _initializerDefineProperty(this, "size", _descriptor2, this);

      _initializerDefineProperty(this, "formLayout", _descriptor3, this);

      _initializerDefineProperty(this, "controlType", _descriptor4, this);

      _initializerDefineProperty(this, "isCheckbox", _descriptor5, this);

      _initializerDefineProperty(this, "isHorizontal", _descriptor6, this);
    }

    get isHorizontalAndNotCheckbox() {
      return this.isHorizontal && !this.isCheckbox;
    }

    get sizeClass() {
      if (!this.isHorizontal) {
        return undefined;
      }

      let size = this.size;
      return Ember.isBlank(size) ? null : "col-form-label-".concat(size);
    }
    /**
     * [BS4 only] Property for size styling, set to 'lg', 'sm'
     *
     * @property size
     * @type String
     * @public
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "invisibleLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isHorizontalAndNotCheckbox"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "controlType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'text';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isCheckbox", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isHorizontal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = FormElementLabel;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementLabel);
});