define("ember-ref-bucket/helpers/ref-to", ["exports", "ember-ref-bucket/utils/ref"], function (_exports, _ref) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RefToHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_watcher", null);
    }

    compute([name], {
      bucket,
      tracked
    }) {
      const bucketRef = bucket || Ember.getOwner(this);

      if (this._name !== name) {
        if (this._watcher) {
          Ember._unregisterDestructor(this, this._watcher);
        }

        this._watcher = (0, _ref.watchFor)(name, bucketRef, () => {
          this.recompute();
        });

        Ember._registerDestructor(this, this._watcher);

        this._name = name;
      }

      if (tracked) {
        return (0, _ref.bucketFor)(bucketRef).getTracked(name);
      } else {
        return (0, _ref.bucketFor)(bucketRef).get(name);
      }
    }

  }

  _exports.default = RefToHelper;
});