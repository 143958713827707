/**

 Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.

 @class ModalBody
 @namespace Components
 @extends Glimmer.Component
 @public
 */
define("ember-bootstrap/components/bs-modal/-body", [], function () {
  "use strict";
});