define("admin-lte-ember/components/alte/breadcrumb-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="breadcrumb-item {{if @active 'active'}}">{{yield}}</li>
  
  */
  {
    "id": "INoEpnbC",
    "block": "[[[10,\"li\"],[15,0,[29,[\"breadcrumb-item \",[52,[30,1],\"active\"]]]],[12],[18,2,null],[13],[1,\"\\n\"]],[\"@active\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/breadcrumb-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});