define("admin-lte-ember/components/alte/content-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          {{#if @name}}
            <h1 class="m-0">{{@name}}</h1>
          {{/if}}
        </div>
        <div class="col-sm-6">
          {{yield}}
        </div>
      </div>
    </div>{{!-- /.container-fluid --}}
  </section>
  
  */
  {
    "id": "3TsCZVLz",
    "block": "[[[10,\"section\"],[14,0,\"content-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container-fluid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row mb-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[10,\"h1\"],[14,0,\"m-0\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@name\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/content-header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});