define("ember-bootstrap/components/bs-popover/element", ["exports", "ember-bootstrap/components/bs-contextual-help/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberPopper
    @ariaRole="tooltip"
    @placement={{this.placement}}
    @renderInPlace={{@renderInPlace}}
    @popperTarget={{@popperTarget}}
    @modifiers={{this.popperModifiers}}
    @popperContainer={{@destinationElement}}
    @onCreate={{this.updatePlacement}}
    @onUpdate={{this.updatePlacement}}
    @id={{@id}}
    @class="popover {{@class}} {{if this.fade "fade"}} {{if (macroCondition (macroGetOwnConfig "isBS4")) (concat "bs-popover-" this.actualPlacement)}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.actualPlacement}} {{if (macroCondition (macroGetOwnConfig "isBS4")) (if this.showHelp "show")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.showHelp "in")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "ember-bootstrap-popover"}}"
    ...attributes
  >
    <div class={{this.arrowClass}}></div>
    {{#if @title}}
      <h3 class="{{if (macroCondition (macroGetOwnConfig "isBS4")) "popover-header"}}{{if (macroCondition (macroGetOwnConfig "isBS3")) "popover-title"}}">{{@title}}</h3>
    {{/if}}
    <div class="{{if (macroCondition (macroGetOwnConfig "isBS4")) "popover-body"}}{{if (macroCondition (macroGetOwnConfig "isBS3")) "popover-content"}}">{{yield}}</div>
  </EmberPopper>
  
  */
  {
    "id": "/nDS5O7o",
    "block": "[[[8,[39,0],[[17,1]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[\"tooltip\",[30,0,[\"placement\"]],[30,2],[30,3],[30,0,[\"popperModifiers\"]],[30,4],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[30,5],[29,[\"popover \",[30,6],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[28,[37,2],[\"bs-popover-\",[30,0,[\"actualPlacement\"]]],null],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n\"],[41,[30,7],[[[1,\"    \"],[10,\"h3\"],[15,0,[29,[\"popover-header\",[27]]]],[12],[1,[30,7]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[\"popover-body\",[27]]]],[12],[18,8,null],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@id\",\"@class\",\"@title\",\"&default\"],false,[\"ember-popper\",\"if\",\"concat\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-popover/element.hbs",
    "isStrictMode": false
  });

  /**
   Internal component for popover's markup. Should not be used directly.
  
   @class PopoverElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  class PopoverElement extends _element.default {
    /**
     * @property title
     * @type string
     * @public
     */
  }

  _exports.default = PopoverElement;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopoverElement);
});