define("ember-bootstrap/components/bs-modal-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsModal
    @open={{@open}}
    @fade={{@fade}}
    @backdrop={{@backdrop}}
    @keyboard={{@keyboard}}
    @position={{@position}}
    @scrollable={{@scrollable}}
    @size={{@size}}
    @backdropClose={{@backdropClose}}
    @renderInPlace={{@renderInPlace}}
    @transitionDuration={{@transitionDuration}}
    @backdropTransitionDuration={{@backdropTransitionDuration}}
    @onSubmit={{@onSubmit}}
    @onHide={{@onHide}}
    @onHidden={{@onHidden}}
    @onShow={{@onShow}}
    @onShown={{@onShown}}
    ...attributes
    as |modal|
  >
    <modal.header @title={{@title}} @closeButton={{@closeButton}} />
    <modal.body>
      {{yield
        (hash
          close=modal.close
          submit=modal.submit
        )
      }}
    </modal.body>
    <modal.footer
      @closeTitle={{@closeTitle}}
      @submitTitle={{@submitTitle}}
      @submitButtonType={{@submitButtonType}}
    />
  </BsModal>
  
  */
  {
    "id": "kZHt8tHi",
    "block": "[[[8,[39,0],[[17,1]],[[\"@open\",\"@fade\",\"@backdrop\",\"@keyboard\",\"@position\",\"@scrollable\",\"@size\",\"@backdropClose\",\"@renderInPlace\",\"@transitionDuration\",\"@backdropTransitionDuration\",\"@onSubmit\",\"@onHide\",\"@onHidden\",\"@onShow\",\"@onShown\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,17]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,18,[\"header\"]],null,[[\"@title\",\"@closeButton\"],[[30,19],[30,20]]],null],[1,\"\\n  \"],[8,[30,18,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,24,[[28,[37,2],null,[[\"close\",\"submit\"],[[30,18,[\"close\"]],[30,18,[\"submit\"]]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,18,[\"footer\"]],null,[[\"@closeTitle\",\"@submitTitle\",\"@submitButtonType\"],[[30,21],[30,22],[30,23]]],null],[1,\"\\n\"]],[18]]]]],[1,\"\\n\"]],[\"&attrs\",\"@open\",\"@fade\",\"@backdrop\",\"@keyboard\",\"@position\",\"@scrollable\",\"@size\",\"@backdropClose\",\"@renderInPlace\",\"@transitionDuration\",\"@backdropTransitionDuration\",\"@onSubmit\",\"@onHide\",\"@onHidden\",\"@onShow\",\"@onShown\",\"modal\",\"@title\",\"@closeButton\",\"@closeTitle\",\"@submitTitle\",\"@submitButtonType\",\"&default\"],false,[\"bs-modal\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal-simple.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});