define("ember-bootstrap/components/bs-form/element/errors", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.show}}
    {{#if this.showMultipleErrors}}
      <div class="pre-scrollable">
        {{#each @messages as |message|}}
          <div class={{if (macroCondition (macroGetOwnConfig "isBS3")) "help-block" "invalid-feedback d-block"}}>{{message}}</div>
        {{/each}}
      </div>
    {{else}}
      <div class={{if (macroCondition (macroGetOwnConfig "isBS3")) "help-block" "invalid-feedback d-block"}}>{{@messages.firstObject}}</div>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "3SfLK2Nk",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[30,0,[\"showMultipleErrors\"]],[[[1,\"    \"],[10,0],[14,0,\"pre-scrollable\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,1,[\"firstObject\"]]],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"@messages\",\"message\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/errors.hbs",
    "isStrictMode": false
  });

  let FormElementErrors = (
  /**
   @class FormElementErrors
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementErrors extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "show", _descriptor, this);

      _initializerDefineProperty(this, "showMultipleErrors", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "show", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showMultipleErrors", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class) || _class);
  _exports.default = FormElementErrors;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementErrors);
});