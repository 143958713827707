define("ember-bootstrap/components/bs-dropdown/menu/link-to", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DropdownMenuLinkTo = (
  /**
  
   Extended `{{link-to}}` component for use within Dropdowns.
  
   @class DropdownMenuLinkTo
   @namespace Components
   @extends Ember.LinkComponent
   @public
   */
  _dec = (0, _component.classNames)('dropdown-item'), _dec(_class = class DropdownMenuLinkTo extends Ember.LinkComponent {}) || _class);
  _exports.default = DropdownMenuLinkTo;
});