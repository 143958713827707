define("ember-bootstrap/helpers/bs-size-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sizeClassHelper = sizeClassHelper;
  _exports.default = void 0;

  function sizeClassHelper([prefix, size], {
    default: defaultValue
  }) {
    var _size;

    size = (_size = size) !== null && _size !== void 0 ? _size : defaultValue;
    return Ember.isBlank(size) ? null : "".concat(prefix, "-").concat(size);
  }

  var _default = Ember.Helper.helper(sizeClassHelper);

  _exports.default = _default;
});