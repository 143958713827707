define("admin-lte-ember/modifiers/alte/main-sidebar", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLASS_MAIN_SIDEBAR = 'main-sidebar';
  const CLASS_SIDEBAR_MINI = 'sidebar-mini';

  var _default = (0, _emberModifier.modifier)(() =>
  /*element, params, hash*/
  {
    document.body.classList.add(CLASS_SIDEBAR_MINI);
    return () => {
      if (!document.querySelector(".".concat(CLASS_MAIN_SIDEBAR))) {
        document.body.classList.remove(CLASS_SIDEBAR_MINI);
      }
    };
  });

  _exports.default = _default;
});