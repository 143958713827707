define("ember-inflector/index", ["exports", "ember-inflector/lib/system"], function (_exports, _system) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "defaultRules", {
    enumerable: true,
    get: function () {
      return _system.defaultRules;
    }
  });
  Object.defineProperty(_exports, "pluralize", {
    enumerable: true,
    get: function () {
      return _system.pluralize;
    }
  });
  Object.defineProperty(_exports, "singularize", {
    enumerable: true,
    get: function () {
      return _system.singularize;
    }
  });
  _exports.default = void 0;
  var _default = _system.Inflector;
  _exports.default = _default;
});