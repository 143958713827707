define("ember-bootstrap/components/bs-form/element/layout/vertical/checkbox", ["exports", "ember-bootstrap/components/bs-form/element/layout/vertical"], function (_exports, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <div class="checkbox">
      {{#component @labelComponent}}
        {{yield}}
      {{/component}}
    </div>
    {{component @errorsComponent}}
    {{component @helpTextComponent}}
  {{else}}
    <div class="form-check">
      {{yield}}
      {{component @labelComponent}}
      {{component @errorsComponent}}
      {{component @helpTextComponent}}
    </div>
  {{/if}}
  */
  {
    "id": "cMqzPc2F",
    "block": "[[[1,\"  \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n    \"],[46,[30,1],null,null,null],[1,\"\\n    \"],[46,[30,2],null,null,null],[1,\"\\n    \"],[46,[30,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/vertical/checkbox.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutVerticalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutVerticalCheckbox extends _vertical.default {}

  _exports.default = FormElementLayoutVerticalCheckbox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementLayoutVerticalCheckbox);
});