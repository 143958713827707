define("ember-bootstrap/components/bs-modal/dialog", ["exports", "@glimmer/component", "ember-bootstrap/utils/deprecate-subclassing", "ember-ref-bucket"], function (_exports, _component, _deprecateSubclassing, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    role="dialog"
    tabindex="-1"
    aria-labelledby={{this.titleId}}
    class="modal {{if @fade "fade"}} {{if @showModal (if (macroCondition (macroGetOwnConfig "isBS4")) "show" "in")}} {{if @inDom (if (macroCondition (macroGetOwnConfig "isBS4")) "d-block" "show")}}"
    ...attributes
    {{on "keydown" this.handleKeyDown}}
    {{on "mousedown" this.handleMouseDown}}
    {{on "mouseup" this.handleMouseUp}}
    {{on "click" this.handleClick}}
    {{style paddingLeft=(concat @paddingLeft "px") paddingRight=(concat @paddingRight "px") display=(if @inDom "block" "")}}
    {{create-ref "mainNode"}}
    {{did-insert this.getOrSetTitleId}}
    {{did-insert this.setInitialFocus}}
  >
    <div class="modal-dialog {{this.sizeClass}} {{if @centered "modal-dialog-centered"}} {{if @scrollable "modal-dialog-scrollable"}}" role="document">
      <div class="modal-content"
       tabindex="-1"
        {{focus-trap
          shouldSelfFocus=true
          focusTrapOptions=(hash
            clickOutsideDeactivates=true
          )
        }}
      >
        {{yield}}
      </div>
    </div>
  </div>
  */
  {
    "id": "hBsUI2zL",
    "block": "[[[11,0],[24,\"role\",\"dialog\"],[24,\"tabindex\",\"-1\"],[16,\"aria-labelledby\",[30,0,[\"titleId\"]]],[16,0,[29,[\"modal \",[52,[30,1],\"fade\"],\" \",[52,[30,2],\"show\"],\" \",[52,[30,3],\"d-block\"]]]],[17,4],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"mousedown\",[30,0,[\"handleMouseDown\"]]],null],[4,[38,1],[\"mouseup\",[30,0,[\"handleMouseUp\"]]],null],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,2],null,[[\"paddingLeft\",\"paddingRight\",\"display\"],[[28,[37,3],[[30,5],\"px\"],null],[28,[37,3],[[30,6],\"px\"],null],[52,[30,3],\"block\",\"\"]]]],[4,[38,4],[\"mainNode\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]],[4,[38,5],[[30,0,[\"getOrSetTitleId\"]]],null],[4,[38,5],[[30,0,[\"setInitialFocus\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"modal-dialog \",[30,0,[\"sizeClass\"]],\" \",[52,[30,7],\"modal-dialog-centered\"],\" \",[52,[30,8],\"modal-dialog-scrollable\"]]]],[14,\"role\",\"document\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"modal-content\"],[24,\"tabindex\",\"-1\"],[4,[38,6],null,[[\"shouldSelfFocus\",\"focusTrapOptions\"],[true,[28,[37,7],null,[[\"clickOutsideDeactivates\"],[true]]]]]],[12],[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@fade\",\"@showModal\",\"@inDom\",\"&attrs\",\"@paddingLeft\",\"@paddingRight\",\"@centered\",\"@scrollable\",\"&default\"],false,[\"if\",\"on\",\"style\",\"concat\",\"create-ref\",\"did-insert\",\"focus-trap\",\"hash\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/dialog.hbs",
    "isStrictMode": false
  });

  let ModalDialog = (
  /**
   Internal component for modal's markup and event handling. Should not be used directly.
  
   @class ModalDialog
   @namespace Components
   @extends Glimmer.Component
   @private
   */
  _dec = (0, _emberRefBucket.ref)('mainNode'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (0, _deprecateSubclassing.default)(_class = (_class2 = class ModalDialog extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_element", _descriptor, this);

      _initializerDefineProperty(this, "titleId", _descriptor2, this);

      _defineProperty(this, "ignoreBackdropClick", false);

      _defineProperty(this, "mouseDownElement", null);
    }

    /**
     * Name of the size class
     *
     * @property sizeClass
     * @type string
     * @readOnly
     * @private
     */
    get sizeClass() {
      let size = this.args.size;
      return Ember.isBlank(size) ? null : "modal-".concat(size);
    }
    /**
     * The id of the `.modal-title` element
     *
     * @property titleId
     * @type string
     * @default null
     * @private
     */


    /**
     * Gets or sets the id of the title element for aria accessibility tags
     *
     * @method getSetTitleID
     * @private
     */
    getOrSetTitleId(modalNode) {
      //Title element may be set by user so we have to try and find it to set the id
      let nodeId = null;

      if (modalNode) {
        const titleNode = modalNode.querySelector('.modal-title');

        if (titleNode) {
          //Get title id of .modal-title
          nodeId = titleNode.id;

          if (!nodeId) {
            //no title id so we set one
            nodeId = "".concat(this.id, "-title");
            titleNode.id = nodeId;
          }
        }
      }

      this.titleId = nodeId;
    }

    setInitialFocus(element) {
      let autofocus = element && element.querySelector('[autofocus]');

      if (autofocus) {
        Ember.run.next(() => autofocus.focus());
      }
    }
    /**
     * If true clicking on the backdrop will be ignored and will not close modal.
     *
     * @property ignoreBackdropClick
     * @type boolean
     * @default false
     * @private
     */


    /**
     * @event onClose
     * @public
     */
    handleKeyDown(e) {
      let code = e.keyCode || e.which;

      if (code === 27 && this.args.keyboard) {
        var _this$args$onClose, _this$args;

        (_this$args$onClose = (_this$args = this.args).onClose) === null || _this$args$onClose === void 0 ? void 0 : _this$args$onClose.call(_this$args);
      }
    }

    handleClick(e) {
      var _this$args$onClose2, _this$args2;

      if (this.ignoreBackdropClick) {
        this.ignoreBackdropClick = false;
        return;
      }

      if (e.target !== this._element || !this.args.backdropClose) {
        return;
      }

      (_this$args$onClose2 = (_this$args2 = this.args).onClose) === null || _this$args$onClose2 === void 0 ? void 0 : _this$args$onClose2.call(_this$args2);
    }

    handleMouseDown(e) {
      this.mouseDownElement = e.target;
    }

    handleMouseUp(e) {
      if (this.mouseDownElement !== this._element && e.target === this._element) {
        this.ignoreBackdropClick = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "titleId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "getOrSetTitleId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "getOrSetTitleId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setInitialFocus", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setInitialFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseDown", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseUp", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseUp"), _class2.prototype)), _class2)) || _class);
  _exports.default = ModalDialog;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalDialog);
});