define("ember-bootstrap/utils/transition-end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.skipTransition = skipTransition;
  _exports.default = waitForTransitionEnd;

  let _skipTransition;

  function skipTransition(bool) {
    _skipTransition = bool;
  }

  function _isSkipped() {
    return _skipTransition === true | _skipTransition !== false && Ember.testing;
  }

  function waitForTransitionEnd(node, duration = 0) {
    if (!node) {
      return Ember.RSVP.reject();
    }

    let backup;

    if (_isSkipped()) {
      duration = 0;
    }

    return new Ember.RSVP.Promise(function (resolve) {
      let done = function () {
        if (backup) {
          Ember.run.cancel(backup);
          backup = null;
        }

        node.removeEventListener('transitionend', done);
        resolve();
      };

      node.addEventListener('transitionend', done, false);
      backup = Ember.run.later(this, done, duration);
    });
  }
});