define("ember-bootstrap/components/bs-navbar/content", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCollapse
    @collapsed={{@collapsed}}
    @onHidden={{@onHidden}}
    @onShown={{@onShown}}
    class="navbar-collapse"
    ...attributes
  >
    {{yield}}
  </BsCollapse>
  
  */
  {
    "id": "DSalKIo7",
    "block": "[[[8,[39,0],[[24,0,\"navbar-collapse\"],[17,1]],[[\"@collapsed\",\"@onHidden\",\"@onShown\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@collapsed\",\"@onHidden\",\"@onShown\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar/content.hbs",
    "isStrictMode": false
  });

  let NavbarContent = (
  /**
   * Component to wrap the collapsible content of a [Components.Navbar](Components.Navbar.html) component.
   * Have a look there for examples.
   *
   * @class NavbarContent
   * @namespace Components
   * @extends Components.Collapse
   * @public
   */
  _dec = (0, _component.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class NavbarContent extends Ember.Component {}) || _class) || _class);
  _exports.default = NavbarContent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NavbarContent);
});