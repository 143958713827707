define("ember-bootstrap/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-header" ...attributes>
    {{#if hasBlockParams}}
      {{yield
        (hash
          title=(bs-default @titleComponent (component "bs-modal/header/title"))
          close=(component (bs-default @closeComponent (component "bs-modal/header/close")) onClick=@onClose)
        )
      }}
    {{else}}
      {{#if (macroCondition (macroGetOwnConfig "isBS4"))}}
        {{#if hasBlock}}
          {{yield}}
        {{else}}
          {{#component (bs-default @titleComponent (component "bs-modal/header/title"))}}{{@title}}{{/component}}
        {{/if}}
        {{#if (bs-default @closeButton true)}}
          {{component (bs-default @closeComponent (component "bs-modal/header/close")) onClick=@onClose}}
        {{/if}}
      {{/if}}
      {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
        {{#if (bs-default @closeButton true)}}
          {{component (bs-default @closeComponent (component "bs-modal/header/close")) onClick=@onClose}}
        {{/if}}
        {{#if hasBlock}}
          {{yield}}
        {{else}}
          {{#component (bs-default @titleComponent (component "bs-modal/header/title"))}}{{@title}}{{/component}}
        {{/if}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "r54sODCh",
    "block": "[[[11,0],[24,0,\"modal-header\"],[17,1],[12],[1,\"\\n\"],[41,[49,[30,7]],[[[1,\"    \"],[18,7,[[28,[37,3],null,[[\"title\",\"close\"],[[28,[37,4],[[30,2],[50,\"bs-modal/header/title\",0,null,null]],null],[50,[28,[37,4],[[30,3],[50,\"bs-modal/header/close\",0,null,null]],null],0,null,[[\"onClick\"],[[30,4]]]]]]]]],[1,\"\\n\"]],[]],[[[41,[48,[30,7]],[[[1,\"        \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[46,[28,[37,4],[[30,2],[50,\"bs-modal/header/title\",0,null,null]],null],null,null,[[\"default\"],[[[[1,[30,5]]],[]]]]],[1,\"\\n\"]],[]]],[41,[28,[37,4],[[30,6],true],null],[[[1,\"        \"],[46,[28,[37,4],[[30,3],[50,\"bs-modal/header/close\",0,null,null]],null],null,[[\"onClick\"],[[30,4]]],null],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&attrs\",\"@titleComponent\",\"@closeComponent\",\"@onClose\",\"@title\",\"@closeButton\",\"&default\"],false,[\"if\",\"has-block-params\",\"yield\",\"hash\",\"bs-default\",\"component\",\"has-block\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/header.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});