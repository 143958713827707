define("ember-bootstrap/components/bs-modal/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form class="modal-footer" ...attributes {{on "submit" (bs-default @onSubmit (bs-noop))}}>
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{#if @submitTitle}}
        {{#component
          (bs-default @buttonComponent (component "bs-button"))
          onClick=@onClose
        }}
          {{bs-default @closeTitle "Ok"}}
        {{/component}}
        {{#let (bs-default @buttonComponent (component "bs-button")) as |Button|}}
          <Button
            @type={{bs-default @submitButtonType "primary"}}
            @onClick={{@onSubmit}}
            disabled={{bs-default @submitDisabled false}}
          >
          {{@submitTitle}}
          </Button>
        {{/let}}
      {{else}}
        {{#component
          (bs-default @buttonComponent (component "bs-button"))
          type="primary"
          onClick=@onClose
        }}
          {{bs-default @closeTitle "Ok"}}
        {{/component}}
      {{/if}}
    {{/if}}
  
  </form>
  */
  {
    "id": "/FJ4+3xn",
    "block": "[[[11,\"form\"],[24,0,\"modal-footer\"],[17,1],[4,[38,0],[\"submit\",[28,[37,1],[[30,2],[28,[37,2],null,null]],null]],null],[12],[1,\"\\n\"],[41,[48,[30,10]],[[[1,\"    \"],[18,10,null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[46,[28,[37,1],[[30,4],[50,\"bs-button\",0,null,null]],null],null,[[\"onClick\"],[[30,5]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[[30,6],\"Ok\"],null]],[1,\"\\n\"]],[]]]]],[44,[[28,[37,1],[[30,4],[50,\"bs-button\",0,null,null]],null]],[[[1,\"        \"],[8,[30,7],[[16,\"disabled\",[28,[37,1],[[30,8],false],null]]],[[\"@type\",\"@onClick\"],[[28,[37,1],[[30,9],\"primary\"],null],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[7]]]],[]],[[[46,[28,[37,1],[[30,4],[50,\"bs-button\",0,null,null]],null],null,[[\"type\",\"onClick\"],[\"primary\",[30,5]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[[30,6],\"Ok\"],null]],[1,\"\\n\"]],[]]]]]],[]]]],[]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@onSubmit\",\"@submitTitle\",\"@buttonComponent\",\"@onClose\",\"@closeTitle\",\"Button\",\"@submitDisabled\",\"@submitButtonType\",\"&default\"],false,[\"on\",\"bs-default\",\"bs-noop\",\"if\",\"has-block\",\"yield\",\"component\",\"let\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});