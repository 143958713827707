define("ember-bootstrap/components/bs-button", ["exports", "macro-decorators", "@glimmer/component", "ember-bootstrap/utils/decorators/arg", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _macroDecorators, _component, _arg, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    disabled={{this.__disabled}}
    type={{this.buttonType}}
    class="btn {{if @active "active"}} {{if this.block "btn-block"}} {{bs-size-class "btn" @size}} {{bs-type-class "btn" @type default=(if (macroCondition (macroGetOwnConfig "isBS3")) "default" "secondary") outline=@outline}}"
    ...attributes
    {{on "click" this.handleClick}}
    {{did-update this.resetState @reset}}
  >
    {{#if this.icon}}<i class={{this.icon}}></i> {{/if}}{{this.text}}{{yield
      (hash
        isFulfilled=this.isFulfilled
        isPending=this.isPending
        isRejected=this.isRejected
        isSettled=this.isSettled
      )
    }}
  </button>
  */
  {
    "id": "097wHUZf",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"__disabled\"]]],[16,4,[30,0,[\"buttonType\"]]],[16,0,[29,[\"btn \",[52,[30,1],\"active\"],\" \",[52,[30,0,[\"block\"]],\"btn-block\"],\" \",[28,[37,1],[\"btn\",[30,2]],null],\" \",[28,[37,2],[\"btn\",[30,3]],[[\"default\",\"outline\"],[\"secondary\",[30,4]]]]]]],[17,5],[4,[38,3],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,4],[[30,0,[\"resetState\"]],[30,6]],null],[12],[1,\"\\n  \"],[41,[30,0,[\"icon\"]],[[[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\" \"]],[]],null],[1,[30,0,[\"text\"]]],[18,7,[[28,[37,6],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[30,0,[\"isFulfilled\"]],[30,0,[\"isPending\"]],[30,0,[\"isRejected\"]],[30,0,[\"isSettled\"]]]]]]],[1,\"\\n\"],[13]],[\"@active\",\"@size\",\"@type\",\"@outline\",\"&attrs\",\"@reset\",\"&default\"],false,[\"if\",\"bs-size-class\",\"bs-type-class\",\"on\",\"did-update\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-button.hbs",
    "isStrictMode": false
  });

  let Button = (
  /**
    Implements a HTML button element, with support for all [Bootstrap button CSS styles](http://getbootstrap.com/css/#buttons)
    as well as advanced functionality such as button states.
  
    ### Basic Usage
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download">
      Downloads
    </BsButton>
    ```
  
    ### Actions
  
    Use the `onClick` property of the component to send an action to your controller. It will receive the button's value
    (see the `value` property) as an argument.
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download" @onClick=(action "download")>
      Downloads
    </BsButton>
    ```
  
    ### Promise support for automatic state change
  
    When returning a Promise for any asynchronous operation from the `onClick` closure action the button will
    manage an internal state ("default" > "pending" > "fulfilled"/"rejected") automatically.
  
    The button is disabled by default if it's in pending state. You could override this behavior by passing
    the `disabled` HTML attribute or by setting `@preventConcurrency` to false.
  
    ```hbs
    <BsButton
      disabled={{false}}
    />
    ```
  
    ```hbs
    <BsButton
      @preventConcurrency={{false}}
    />
    ```
  
    The label could be changed automatically according to the state of the promise with `@defaultText`,
    `@pendingText`, `@fulfilledText` and `@rejectedText` arguments:
  
    ```hbs
    <BsButton
      @type="primary"
      @icon="glyphicon glyphicon-download"
      @defaultText="Download"
      @pendingText="Loading..."
      @fulfilledText="Completed!"
      @rejectedText="Oups!?"
      @onClick={{this.download}}
    />
    ```
  
    ```js
    // controller.js
    import { Controller } from '@ember/controller';
    import { action } from '@ember/object';
  
    export default class MyController extends Controller {
      @action
      download(value) {
        return new Promise(...);
      }
    });
    ```
  
    For further customization `isPending`, `isFulfilled`, `isRejected` and `isSettled` properties are yielded:
  
    ```hbs
    <BsButton @onClick=(action "download") as |button|>
      Download
      {{#if button.isPending}}
        <span class="loading-spinner"></span>
      {{/if}}
    </BsButton>
    ```
  
    You can `reset` the state represented by these properties and used for button's text by setting `reset` property to
    `true`.
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Button
    @namespace Components
    @extends Glimmer.Component
    @public
  */
  _dec = Ember._tracked, _dec2 = (0, _macroDecorators.equal)('state', 'pending'), _dec3 = (0, _macroDecorators.equal)('state', 'fulfilled'), _dec4 = (0, _macroDecorators.equal)('state', 'rejected'), _dec5 = (0, _macroDecorators.or)('isFulfilled', 'isRejected'), _dec6 = Ember._action, _dec7 = Ember._action, (0, _deprecateSubclassing.default)(_class = (_class2 = class Button extends _component.default {
    /**
     * Default label of the button. Not need if used as a block component
     *
     * @property defaultText
     * @type string
     * @public
     */

    /**
     * Label of the button used if `onClick` event has returned a Promise which is pending.
     * Not considered if used as a block component.
     *
     * @property pendingText
     * @type string
     * @public
     */

    /**
     * Label of the button used if `onClick` event has returned a Promise which succeeded.
     * Not considered if used as a block component.
     *
     * @property fulfilledText
     * @type string
     * @public
     */

    /**
     * Label of the button used if `onClick` event has returned a Promise which failed.
     * Not considered if used as a block component.
     *
     * @property rejectedText
     * @type string
     * @public
     */

    /**
     * Property to disable the button only used in internal communication
     * between Ember Boostrap components.
     *
     * @property _disabled
     * @type ?boolean
     * @default null
     * @private
     */
    get __disabled() {
      if (this.args._disabled !== undefined) {
        return this.args._disabled;
      }

      return this.isPending && this.args.preventConcurrency !== false;
    }
    /**
     * Set the type of the button, either 'button' or 'submit'
     *
     * @property buttonType
     * @type String
     * @default 'button'
     * @deprecated
     * @public
     */


    /**
     * A click event on a button will not bubble up the DOM tree if it has an `onClick` action handler. Set to true to
     * enable the event to bubble
     *
     * @property bubble
     * @type boolean
     * @default false
     * @public
     */

    /**
     * If button is active and this is set, the icon property will match this property
     *
     * @property iconActive
     * @type String
     * @public
     */

    /**
     * If button is inactive and this is set, the icon property will match this property
     *
     * @property iconInactive
     * @type String
     * @public
     */

    /**
     * Class(es) (e.g. glyphicons or font awesome) to use as a button icon
     * This will render a <i class="{{icon}}"></i> element in front of the button's label
     *
     * @property icon
     * @type String
     * @public
     */
    get icon() {
      return this.args.icon || (this.args.active ? this.args.iconActive : this.args.iconInactive);
    }
    /**
     * Supply a value that will be associated with this button. This will be send
     * as a parameter of the default action triggered when clicking the button
     *
     * @property value
     * @type any
     * @public
     */

    /**
     * Controls if `onClick` action is fired concurrently. If `true` clicking button multiple times will not trigger
     * `onClick` action if a Promise returned by previous click is not settled yet.
     *
     * This does not affect event bubbling.
     *
     * @property preventConcurrency
     * @type Boolean
     * @default true
     * @public
     */

    /**
     * State of the button. The button's label (if not used as a block component) will be set to the
     * `<state>Text` property.
     * This property will automatically be set when using a click action that supplies the callback with a promise.
     * Possible values are: "default" > "pending" > "fulfilled" / "rejected".
     * It could be reseted by `reset` property.
     *
     * @property state
     * @type String
     * @default 'default'
     * @private
     */


    get state() {
      var _this$args$state;

      return (_this$args$state = this.args.state) !== null && _this$args$state !== void 0 ? _this$args$state : this._state;
    }

    set state(state) {
      this._state = state;
    }
    /**
     * Promise returned by `onClick` event is pending.
     *
     * @property isPending
     * @type Boolean
     * @private
     */


    /**
     * Set this to `true` to reset the `state`. A typical use case is to bind this attribute with ember-data isDirty flag.
     *
     * The old value is not taken into consideration. Setting a `true` value to `true` again will also reset `state`.
     * In that case it's even to notify the observer system that the property has changed by calling
     * [`notifyPropertyChange()`](https://www.emberjs.com/api/ember/3.2/classes/EmberObject/methods/notifyPropertyChange?anchor=notifyPropertyChange).
     *
     * @property reset
     * @type boolean
     * @public
     */

    /**
     * Property for size styling, set to 'lg', 'sm' or 'xs'
     *
     * Also see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/buttons/#sizes)
     *
     * @property size
     * @type String
     * @public
     */

    /**
     * Property for type styling
     *
     * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/buttons/)
     *
     * @property type
     * @type String
     * @default 'secondary'
     * @public
     */

    /**
     * Property to create outline buttons (BS4+ only)
     *
     * @property disabled
     * @type boolean
     * @default false
     * @public
     */

    /**
     * When clicking the button this action is called with the value of the button (that is the value of the "value" property).
     *
     * Return a promise object, and the buttons state will automatically set to "pending", "resolved" and/or "rejected".
     * This could be used to automatically set the button's text depending on promise state (`defaultText`, `pendingText`,
     * `fulfilledText`, `rejectedText`) and for futher customization using the yielded `isPending`, `isFulfilled`,
     * `isRejected` properties.
     *
     * The click event will not bubble up, unless you set `bubble` to true.
     *
     * @event onClick
     * @param {*} value
     * @public
     */

    /**
     * This will reset the state property to 'default', and with that the button's label to defaultText
     *
     * @method resetState
     * @private
     */
    resetState() {
      this.state = 'default';
    }

    get text() {
      return this.args["".concat(this.state, "Text")] || this.args.defaultText;
    }
    /**
     * @method click
     * @private
     */


    handleClick(e) {
      let onClick = this.args.onClick;
      let preventConcurrency = this.args.preventConcurrency;

      if (typeof onClick !== 'function') {
        return;
      }

      if (!preventConcurrency || !this.isPending) {
        let promise = onClick(this.args.value);

        if (promise && typeof promise.then === 'function' && !this.isDestroyed) {
          this.state = 'pending';
          promise.then(() => {
            if (!this.isDestroyed) {
              this.state = 'fulfilled';
            }
          }, () => {
            if (!this.isDestroyed) {
              this.state = 'rejected';
            }
          });
        }
      }

      if (!this.args.bubble) {
        e.stopPropagation();
      }
    }

    constructor() {
      super(...arguments); // deprecate arguments used for attribute bindings only

      _initializerDefineProperty(this, "buttonType", _descriptor, this);

      _initializerDefineProperty(this, "block", _descriptor2, this);

      _initializerDefineProperty(this, "_state", _descriptor3, this);

      _initializerDefineProperty(this, "isPending", _descriptor4, this);

      _initializerDefineProperty(this, "isFulfilled", _descriptor5, this);

      _initializerDefineProperty(this, "isRejected", _descriptor6, this);

      _initializerDefineProperty(this, "isSettled", _descriptor7, this);

      if (false
      /* DEBUG */
      ) {
        [// ['buttonType:type', 'submit'],
        ['disabled', true], ['title', 'foo']].forEach(([mapping, value]) => {
          let argument = mapping.split(':')[0];
          let attribute = mapping.includes(':') ? mapping.split(':')[1] : argument;
          let warningMessage = "Argument ".concat(argument, " of <BsButton> component has been removed. Its only purpose ") + "was setting the HTML attribute ".concat(attribute, " of the control element. You should use ") + "angle bracket component invocation syntax instead:\n" + "Before:\n" + "  {{bs-button ".concat(attribute, "=").concat(typeof value === 'string' ? "\"".concat(value, "\"") : value, "}}\n") + "  <BsButton @".concat(attribute, "=").concat(typeof value === 'string' ? "\"".concat(value, "\"") : "{{".concat(value, "}}"), " />\n") + "After:\n" + "  <BsButton ".concat(typeof value === 'boolean' ? attribute : "".concat(attribute, "=\"").concat(value, "\""), " />\n") + "A codemod is available to help withFi the required migration. See https://github.com/kaliber5/ember-bootstrap-codemods/blob/master/transforms/deprecated-attribute-arguments/README.md";
          (false && Ember.warn(warningMessage, !Object.keys(this.args).includes(argument), {
            id: "ember-bootstrap.removed-argument.button#".concat(argument)
          }));
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "buttonType", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "block", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "_state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isPending", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isFulfilled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isRejected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isSettled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "resetState", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "resetState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class);
  _exports.default = Button;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Button);
});