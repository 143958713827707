define("ember-render-helpers/helpers/will-destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * This helper is activated immediately before the helper is un-rendered
   * (removed from the DOM). It does not run during or after initial render, or
   * when its arguments are updated.
   */
  class WillDestroyHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fn", void 0);

      _defineProperty(this, "positional", void 0);

      _defineProperty(this, "named", void 0);
    }

    compute(positional, named) {
      const fn = positional[0];
      (false && !(typeof fn === 'function') && Ember.assert("`{{did-insert fn}}` expects a function as the first parameter. You provided: ".concat(fn), typeof fn === 'function'));
      this.fn = fn;
      this.positional = positional.slice(1);
      this.named = named;
    }

    willDestroy() {
      if (this.fn && this.positional && this.named) {
        const {
          fn
        } = this;
        fn(this.positional, this.named);
      }

      super.willDestroy();
    }

  }

  _exports.default = WillDestroyHelper;
});