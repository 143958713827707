define("admin-lte-ember/components/alte/card", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="card {{if @type (concat " card-" @type)}}">
    {{yield (hash
      header = (component 'alte/card/header' collapsable=this.collapsable collapsed=@collapsed onCollapse=@onCollapse)
      content = (component 'alte/card/content' collapsable=this.collapsable collapsed=@collapsed)
    )}}
    {{#if @loading}}
      <div class="overlay">
        <LoadingSpinner @size="4x" />
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "2wt1AO9e",
    "block": "[[[11,0],[17,1],[16,0,[29,[\"card \",[52,[30,2],[28,[37,1],[\" card-\",[30,2]],null]]]]],[12],[1,\"\\n  \"],[18,6,[[28,[37,3],null,[[\"header\",\"content\"],[[50,\"alte/card/header\",0,null,[[\"collapsable\",\"collapsed\",\"onCollapse\"],[[30,0,[\"collapsable\"]],[30,3],[30,4]]]],[50,\"alte/card/content\",0,null,[[\"collapsable\",\"collapsed\"],[[30,0,[\"collapsable\"]],[30,3]]]]]]]]],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[14,0,\"overlay\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@size\"],[\"4x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@type\",\"@collapsed\",\"@onCollapse\",\"@loading\",\"&default\"],false,[\"if\",\"concat\",\"yield\",\"hash\",\"component\",\"loading-spinner\"]]",
    "moduleName": "admin-lte-ember/components/alte/card.hbs",
    "isStrictMode": false
  });

  class AlteLteCard extends _component.default {
    get collapsable() {
      return this.args.collapsed != undefined;
    }

  }

  _exports.default = AlteLteCard;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AlteLteCard);
});