define("ember-bootstrap/components/bs-nav/link-to", ["exports", "ember-bootstrap/mixins/component-child", "@ember-decorators/component"], function (_exports, _componentChild, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let NavLinkTo = (
  /**
  
   Extended `{{link-to}}` component for use within Navs.
  
   @class NavLinkTo
   @namespace Components
   @extends Ember.LinkComponent
   @uses Mixins.ComponentChild
   @public
   */
  _dec = (0, _component.classNames)('nav-link'), _dec(_class = class NavLinkTo extends Ember.LinkComponent.extend(_componentChild.default) {}) || _class);
  _exports.default = NavLinkTo;
});