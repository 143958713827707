define("ember-bootstrap/components/bs-accordion/item/body", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCollapse @collapsed={{@collapsed}} class={{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-collapse"}} role="tabpanel">
    <div class="{{if (macroCondition (macroGetOwnConfig "isBS4")) "card-body"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-body"}} {{@class}}">
      {{yield}}
    </div>
  </BsCollapse>
  */
  {
    "id": "jSvzNstO",
    "block": "[[[8,[39,0],[[16,0,[27]],[24,\"role\",\"tabpanel\"]],[[\"@collapsed\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"card-body\",\" \",[27],\" \",[30,2]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@collapsed\",\"@class\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item/body.hbs",
    "isStrictMode": false
  });

  let AccordionItemBody = (
  /**
   Component for an accordion item body.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemBody
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class AccordionItemBody extends Ember.Component {
    /**
     * @property collapsed
     * @type boolean
     * @public
     */
  }) || _class) || _class);
  _exports.default = AccordionItemBody;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AccordionItemBody);
});