define("admin-lte-ember/components/alte/breadcrumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ol class="breadcrumb float-sm-right">
    {{yield}}
  </ol>
  
  */
  {
    "id": "NACYxBcd",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb float-sm-right\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/breadcrumb.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});