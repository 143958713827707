define("ember-bootstrap/components/bs-tooltip/element", ["exports", "ember-bootstrap/components/bs-contextual-help/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberPopper
    @ariaRole="tooltip"
    @placement={{this.placement}}
    @renderInPlace={{@renderInPlace}}
    @popperTarget={{@popperTarget}}
    @modifiers={{this.popperModifiers}}
    @popperContainer={{@destinationElement}}
    @onCreate={{this.updatePlacement}}
    @onUpdate={{this.updatePlacement}}
    @id={{@id}}
    @class="tooltip {{@class}} {{if this.fade "fade"}} {{if (macroCondition (macroGetOwnConfig "isBS4")) (concat "bs-tooltip-" this.actualPlacement)}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.actualPlacement}} {{if (macroCondition (macroGetOwnConfig "isBS4")) (if this.showHelp "show")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.showHelp "in")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "ember-bootstrap-tooltip"}}"
    ...attributes
  >
    <div class={{this.arrowClass}}></div>
    <div class="tooltip-inner">
      {{yield}}
    </div>
  </EmberPopper>
  
  */
  {
    "id": "5VHwY4UV",
    "block": "[[[8,[39,0],[[17,1]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[\"tooltip\",[30,0,[\"placement\"]],[30,2],[30,3],[30,0,[\"popperModifiers\"]],[30,4],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[30,5],[29,[\"tooltip \",[30,6],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[28,[37,2],[\"bs-tooltip-\",[30,0,[\"actualPlacement\"]]],null],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"tooltip-inner\"],[12],[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@id\",\"@class\",\"&default\"],false,[\"ember-popper\",\"if\",\"concat\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-tooltip/element.hbs",
    "isStrictMode": false
  });

  /**
   Internal component for tooltip's markup. Should not be used directly.
  
   @class TooltipElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  class TooltipElement extends _element.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "arrowClass", 'arrow');
    }

  }

  _exports.default = TooltipElement;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TooltipElement);
});