define("ember-bootstrap/components/bs-progress", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="progress" ...attributes>
    {{yield
      (hash
        bar=(bs-default @progressBarComponent (component "bs-progress/bar"))
      )
    }}
  </div>
  */
  {
    "id": "0bt/x04z",
    "block": "[[[11,0],[24,0,\"progress\"],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"bar\"],[[28,[37,2],[[30,2],[50,\"bs-progress/bar\",0,null,null]],null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@progressBarComponent\",\"&default\"],false,[\"yield\",\"hash\",\"bs-default\",\"component\"]]",
    "moduleName": "ember-bootstrap/components/bs-progress.hbs",
    "isStrictMode": false
  });

  let Progress = (
  /**
    Component to display a Bootstrap progress bar, see http://getbootstrap.com/components/#progress.
  
    ### Usage
  
    The component yields a [Components.ProgressBar)(Components.ProgressBar.html) component that represents a single bar.
    Use the `value` property to control the progress bar's width. To apply the different styling options supplied by
    Bootstrap, use the appropriate properties like `type`, `showLabel`, `striped` or `animate`.
  
    ```hbs
    <BsProgress as |pg| >
      <pg.bar @value={{this.progressValue}} @minValue={{0}} @maxValue={{10}} @showLabel={{true}} @type="danger" />
    </BsProgress>
    ```
  
    ### Stacked
  
    You can place multiple progress bar components in a single progress component to
    create a stack of progress bars as seen in http://getbootstrap.com/components/#progress-stacked.
  
    ```hbs
    <BsProgress as |pg| >
      <pg.bar @value={{this.progressValue1}} @type="success" />
      <pg.bar @value={{this.progressValue2}} @type="warning" />
      <pg.bar @value={{this.progressValue3}} @type="danger" />
    </BsProgress>
    ```
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Progress
    @namespace Components
    @extends Ember.Component
    @public
  */
  _dec = (0, _component.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class Progress extends Ember.Component {
    /**
     * @property progressBarComponent
     * @type {String}
     * @private
     */
  }) || _class) || _class);
  _exports.default = Progress;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Progress);
});