define("ember-bootstrap/components/bs-form/element/layout/vertical", ["exports", "ember-bootstrap/components/bs-form/element/layout"], function (_exports, _layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.hasLabel}}
    {{component @labelComponent}}
  {{/if}}
  {{yield}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    {{component @feedbackIconComponent}}
  {{/if}}
  {{component @errorsComponent}}
  {{component @helpTextComponent}}
  */
  {
    "id": "K1aCwdkB",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[46,[30,1],null,null,null],[1,\"\\n\"]],[]],null],[18,4,null],[1,\"\\n\"],[46,[30,2],null,null,null],[1,\"\\n\"],[46,[30,3],null,null,null]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"component\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/vertical.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutVertical
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutVertical extends _layout.default {}

  _exports.default = FormElementLayoutVertical;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementLayoutVertical);
});