define("ember-bootstrap/components/bs-button-group", ["exports", "@glimmer/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="{{if @vertical "btn-group-vertical" "btn-group"}} {{bs-size-class "btn-group" @size}} {{if @justified (if (macroCondition (macroGetOwnConfig "isBS3")) "btn-group-justified")}}" role="group" ...attributes>
    {{yield
      (hash
        button=(component (bs-default @buttonComponent (component "bs-button-group/button")) buttonGroupType=@type groupValue=@value onClick=this.buttonPressed)
      )
    }}
  </div>
  */
  {
    "id": "adcvjUVF",
    "block": "[[[11,0],[16,0,[29,[[52,[30,1],\"btn-group-vertical\",\"btn-group\"],\" \",[28,[37,1],[\"btn-group\",[30,2]],null],\" \",[52,[30,3],[27]]]]],[24,\"role\",\"group\"],[17,4],[12],[1,\"\\n  \"],[18,8,[[28,[37,3],null,[[\"button\"],[[50,[28,[37,5],[[30,5],[50,\"bs-button-group/button\",0,null,null]],null],0,null,[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[30,6],[30,7],[30,0,[\"buttonPressed\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"@vertical\",\"@size\",\"@justified\",\"&attrs\",\"@buttonComponent\",\"@type\",\"@value\",\"&default\"],false,[\"if\",\"bs-size-class\",\"yield\",\"hash\",\"component\",\"bs-default\"]]",
    "moduleName": "ember-bootstrap/components/bs-button-group.hbs",
    "isStrictMode": false
  });

  let ButtonGroup = (
  /**
    Bootstrap-style button group, that visually groups buttons, and optionally adds radio/checkbox like behaviour.
    See http://getbootstrap.com/components/#btn-groups
  
    Use as a block level component with any number of [Components.Button](Components.Button.html) components provided as
    a yielded pre-configured contextual component:
  
    ```handlebars
    <BsButtonGroup as |bg|>
      <bg.button>1</bg.button>
      <bg.button>2</bg.button>
      <bg.button>3</bg.button>
    </BsButtonGroup>
    ```
  
    ### Radio-like behaviour
  
    Use the `type` property set to "radio" to make the child buttons toggle like radio buttons, i.e. only one button can be active.
    Set the `value` property of the buttons to something meaningful. The `value` property of the button group will then reflect
    the value of the active button:
  
    ```handlebars
    <BsButtonGroup @value={{this.buttonGroupValue}} @type="radio" @onChange={{action (mut this.buttonGroupValue}} as |bg|>
      <bg.button @type="default" @value={{1}}>1</bg.button>
      <bg.button @type="default" @value={{2}}>2</bg.button>
      <bg.button @type="default" @value={{3}}>3</bg.button>
    </BsButtonGroup>
  
    You selected: {{this.buttonGroupValue}}!
    ```
  
    ### Checkbox-like behaviour
  
    Set `type` to "checkbox" to make any number of child buttons selectable. The `value` property will be an array
    of all the values of the active buttons:
  
    ```handlebars
    <BsButtonGroup @value={{this.buttonGroupValue}} @type="checkbox" @onChange={{action (mut this.buttonGroupValue}} as |bg|>
      <bg.button @type="default" @value={{1}}>1</bg.button>
      <bg.button @type="default" @value={{2}}>2</bg.button>
      <bg.button @type="default" @value={{3}}>3</bg.button>
    </BsButtonGroup>
  
    You selected:
    <ul>
      {{#each value in this.buttonGroupValue}}
        <li>{{value}}</li>
      {{/each}}
    </ul>
    ```
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class ButtonGroup
    @namespace Components
    @extends Glimmer.Component
    @public
  */
  _dec = Ember._action, (0, _deprecateSubclassing.default)(_class = (_class2 = class ButtonGroup extends _component.default {
    /**
     * @property buttonComponent
     * @type {String}
     * @private
     */

    /**
     * Set to true for a vertically stacked button group, see http://getbootstrap.com/components/#btn-groups-vertical
     *
     * @property vertical
     * @type boolean
     * @default false
     * @public
     */

    /**
     * Set to true for the buttons to stretch at equal sizes to span the entire width of its parent. (BS3 only!)
     *
     * *Important*: You have to wrap every button component in a `div class="btn-group">`:
     *
     * ```handlebars
     * <div class="btn-group" role="group">
     * {{#bs-button}}My Button{{/bs-button}}
     * </div>
     * ```
     *
     * See http://getbootstrap.com/components/#btn-groups-justified
     *
     * @property justified
     * @type boolean
     * @default false
     * @public
     */

    /**
     * The type of the button group specifies how child buttons behave and how the `value` property will be computed:
     *
     * ### null
     * If `type` is not set (null), the button group will add no functionality besides Bootstrap styling
     *
     * ### radio
     * if `type` is set to "radio", the buttons will behave like radio buttons:
     * * the `value` property of the button group will reflect the `value` property of the active button
     * * thus only one button may be active
     *
     * ### checkbox
     * if `type` is set to "checkbox", the buttons will behave like checkboxes:
     * * any number of buttons may be active
     * * the `value` property of the button group will be an array containing the `value` properties of all active buttons
     *
     * @property type
     * @type string
     * @default null
     * @public
     */

    /**
     * The value of the button group, computed by its child buttons.
     * See the `type` property for how the value property is constructed.
     *
     * When you set the value, the corresponding buttons will be activated:
     * * use a single value for a radio button group to activate the button with the same value
     * * use an array of values for a checkbox button group to activate all the buttons with values contained in the array
     *
     * @property value
     * @type array
     * @public
     */

    /**
     * Property for size styling, set to 'lg', 'sm' or 'xs'
     *
     * Also see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/button-group/#sizing)
     *
     * @property size
     * @type String
     * @public
     */

    /**
     * This action is called whenever the button group's value should be changed because the user clicked a button.
     * You will receive the new value of the button group (based on the `type` property), which you should use to update the
     * `value` property.
     *
     * @event onChange
     * @param {*} value
     * @public
     */
    buttonPressed(pressedValue) {
      if (!this.args.onChange) {
        return;
      }

      let newValue;

      if (this.args.type === 'radio') {
        newValue = pressedValue;
      } else {
        if (!Ember.isArray(this.args.value)) {
          newValue = [pressedValue];
        } else {
          if (this.args.value.includes(pressedValue)) {
            newValue = this.args.value.filter(v => v !== pressedValue);
          } else {
            newValue = [...this.args.value, pressedValue];
          }
        } // For compatibility we continue to return an EmberArray instance for now
        // @todo this should be changed for the next major release!


        newValue = Ember.A(newValue);
      }

      this.args.onChange(newValue);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "buttonPressed", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonPressed"), _class2.prototype)), _class2)) || _class);
  _exports.default = ButtonGroup;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ButtonGroup);
});