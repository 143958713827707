/**

 @class ModalHeaderClose
 @namespace Components
 @extends Glimmer.Component
 @private
 */

/**
 * @event onClick
 * @public
 */
define("ember-bootstrap/components/bs-modal/header/-close", [], function () {
  "use strict";
});