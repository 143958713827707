define("admin-lte-ember/components/alte/card/header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="card-header {{if this.noBottomBorder " border-bottom-0"}}">
    {{#if @title}}
      <h3 class="card-title {{@titleClasses}}">
        {{#if @titleIcon}}
          {{#if @titleIconPrefix}}
            <FaIcon class="mr-1" @prefix={{@titleIconPrefix}} @icon={{@titleIcon}} />
          {{else}}
            <FaIcon class="mr-1" @icon={{@titleIcon}} />
          {{/if}}
        {{/if}}
        {{@title}}
      </h3>
    {{/if}}
    {{#if @collapsable}}
      <div class="card-tools">
        <BsButton @type="none" @onClick={{@onCollapse}} class="btn-tool">
          {{#if @collapsed}}
            <FaIcon @icon="plus" />
          {{else}}
            <FaIcon @icon="minus" />
          {{/if}}
        </BsButton>
      </div>
    {{/if}}
    {{yield}}
  </div>
  
  */
  {
    "id": "eMIJ6LCk",
    "block": "[[[11,0],[17,1],[16,0,[29,[\"card-header \",[52,[30,0,[\"noBottomBorder\"]],\" border-bottom-0\"]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"h3\"],[15,0,[29,[\"card-title \",[30,3]]]],[12],[1,\"\\n\"],[41,[30,4],[[[41,[30,5],[[[1,\"          \"],[8,[39,1],[[24,0,\"mr-1\"]],[[\"@prefix\",\"@icon\"],[[30,5],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,1],[[24,0,\"mr-1\"]],[[\"@icon\"],[[30,4]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,6],[[[1,\"    \"],[10,0],[14,0,\"card-tools\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"btn-tool\"]],[[\"@type\",\"@onClick\"],[\"none\",[30,7]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,8],[[[1,\"          \"],[8,[39,1],null,[[\"@icon\"],[\"plus\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,1],null,[[\"@icon\"],[\"minus\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,9,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@titleClasses\",\"@titleIcon\",\"@titleIconPrefix\",\"@collapsable\",\"@onCollapse\",\"@collapsed\",\"&default\"],false,[\"if\",\"fa-icon\",\"bs-button\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/card/header.hbs",
    "isStrictMode": false
  });

  class AlteLteCardHeader extends _component.default {
    get noBottomBorder() {
      return this.args.collapsed || this.args.noBottomBorder;
    }

  }

  _exports.default = AlteLteCardHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AlteLteCardHeader);
});