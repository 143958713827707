define("ember-bootstrap/components/bs-form/element/help-text", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    id={{@id}}
    class={{if (macroCondition (macroGetOwnConfig "isBS3")) "help-block" "form-text"}}
    ...attributes
  >
    {{@text}}
  </div>
  */
  {
    "id": "AcjbIET9",
    "block": "[[[11,0],[16,1,[30,1]],[24,0,\"form-text\"],[17,2],[12],[1,\"\\n  \"],[1,[30,3]],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"@text\"],false,[]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/help-text.hbs",
    "isStrictMode": false
  });

  let FormElementHelpText = (
  /**
  
   @class FormElementHelpText
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class FormElementHelpText extends Ember.Component {}) || _class) || _class);
  _exports.default = FormElementHelpText;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormElementHelpText);
});